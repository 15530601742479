import Graduates from "./Graduates";
import "./GraduatesRow.css";
import React, { useState, useEffect } from "react";
import LeftArrow from "../images/landingPage_March_OPEN-18.png";
import RightArrow from "../images/landingPage_March_OPEN-17.png";

function GraduatesRow({ graduatesData }) {
  const initialValues = [0, 3, 6];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedGraduates, setDisplayedGraduates] = useState(1);
  const [plus, setPlus] = useState(3);

  const shuffleArray = (array) => {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  useEffect(() => {
    const shuffledValues = shuffleArray([...initialValues]);
    setCurrentIndex(shuffledValues[0]);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setDisplayedGraduates(1);
        setPlus(1);
      } else {
        setPlus(3);
        setDisplayedGraduates(3);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const nextGraduate = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === graduatesData.length - plus ? 0 : prevIndex + plus
    );
  };

  const prevGraduate = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? graduatesData.length - plus : prevIndex - plus
    );
  };

  return (
    <div className="graduates-row-page">
      <h2 className="title-graudates">הבוגרים של ספיר</h2>
      <div className="gallery">
        <div className="button-container">
          <button className="buttons" onClick={prevGraduate}>
            <img src={LeftArrow} className="arrows-images" />
          </button>
          {graduatesData
            .slice(currentIndex, currentIndex + displayedGraduates)
            .map((graduate, index) => (
              <Graduates
                key={index}
                imageUrl={graduate.imageUrl}
                title={graduate.title}
                miniTitle={graduate.miniTitle}
                details={graduate.details}
              />
            ))}
          <button className="buttons" onClick={nextGraduate}>
            <img src={RightArrow} className="arrows-images" />
          </button>
        </div>
      </div>
      <br />
    </div>
  );
}

export default GraduatesRow;
