import GraduateOne from "../images/graduates/landingPage_March_OPEN-05.png";
import GraduateTwo from "../images/graduates/landingPage_March_OPEN-06.png";
import GraduateThree from "../images/graduates/landingPage_March_OPEN-07.png";
import GraduateThirty from "../images/graduates/landingPage_March_OPEN-30.png";
import GraduateThirtyOne from "../images/graduates/landingPage_March_OPEN-31.png";
import GraduateThirtyTwo from "../images/graduates/landingPage_March_OPEN-32.png";
import GraduateThirtyThree from "../images/graduates/landingPage_March_OPEN-33.png";
import GraduateThirtyFour from "../images/graduates/landingPage_March_OPEN-34.png";
import GraduateThirtyFive from "../images/graduates/landingPage_March_OPEN-35.png";
import GraduateThirtySix from "../images/graduates/landingPage_March_OPEN-36.png";
import GraduateThirtySeven from "../images/graduates/landingPage_March_OPEN-37.png";
import GraduateThirtyEight from "../images/graduates/landingPage_March_OPEN-38.png";
import GraduateFourtyOne from "../images/graduates/landingPage_March_OPEN-41.png";
import GraduateFourtyTwo from "../images/graduates/landingPage_March_OPEN-42.png";
import GraduateFourtyFour from "../images/graduates/landingPage_March_OPEN-44.png";

const graduatesData = [
  {
    imageUrl: GraduateOne,
    title: "אלמוג בוקר",
    miniTitle: "בוגר תואר ראשון בתקשורת",
    details: "כתב ומגיש, חדשות 13",
  },
  {
    imageUrl: GraduateFourtyTwo,
    title: "יפה יטמנו",
    miniTitle: 'בוגרת תואר ראשון בביה"ס למשפטים',
    details: 'ע"וד בכירה במשרד שעוסק בדיני משפחה ואפוטרופסות',
  },
  {
    imageUrl: GraduateTwo,
    title: "גברי שניידר",
    miniTitle: "בוגר תואר ראשון בעבודה סוציאלית",
    details: 'קב"ן בצה"ל',
  },
  {
    imageUrl: GraduateThirtyThree,
    title: "נדב טייטלבוים",
    miniTitle: "בוגר תואר ראשון בלימודים רב תחומיים",
    details: "אמן, מלמד אומנות בבית חולים שניידר ובמגמת אומנות בתיכון",
  },
  {
    imageUrl: GraduateThree,
    title: "גיל הראל",
    miniTitle: "בוגר תואר ראשון בכלכלה יישומית וניהול",
    details: "Data Engineer",
  },
  {
    imageUrl: GraduateThirtyFour,
    title: "נופר עמר",
    miniTitle: "בוגרת תואר ראשון בכלכלה וחשבונאות",
    details: "רואת חשבון, מרצה וסטודנטית לתואר שני",
  },

  {
    imageUrl: GraduateThirty,
    title: "דותן ינקו בן חיים",
    miniTitle: "בוגר תואר ראשון בשיווק טכנולוגי",
    details:
      " מנהל מוצר בכיר בחברת תקשורת קנדית. תואר שני במנהל עסקים University of British Columbia",
  },
  {
    imageUrl: GraduateThirtyFive,
    title: "עינבל משש",
    miniTitle: "בוגרת תואר ראשון במנהל ומדיניות ציבורית",
    details: 'מנכ"לית משרד התקשורת',
  },
  {
    imageUrl: GraduateThirtyOne,
    title: "אושרית סרוסי",
    miniTitle: "בוגרת תואר ראשון באמנויות הקול והמסך",
    details: "יוצרת, תסריטאית ושחקנית",
  },
  {
    imageUrl: GraduateThirtyEight,
    title: "אנה סולודוחין",
    miniTitle: "בוגרת תואר ראשון במדעי המחשב",
    details: "מפתחת תוכנה באמדוקס",
  },
  {
    imageUrl: GraduateThirtyTwo,
    title: "ירין חזיזה",
    miniTitle: "בוגר תואר ראשון בניהול תעשייתי",
    details: "מתכנן תכנון ביקושים ואספקות בשטראוס",
  },

  {
    imageUrl: GraduateThirtySix,
    title: "עמית סער",
    miniTitle: "בוגרת תואר ראשון בניהול המשאב האנושי",
    details: "עובדת בצוות HR בחברת Morphisec",
  },
  {
    imageUrl: GraduateThirtySeven,
    title: "עמרי חורש",
    miniTitle: "בוגר תואר ראשון בתרבות - יצירה והפקה",
    details: "סופר, יזם תרבות ומנהל הספרייה והארכיון בסינמטק תל אביב",
  },
  {
    imageUrl: GraduateFourtyFour,
    title: "מאי וייץ בן ארי",
    miniTitle: "בוגרת הנדסה שנה א'",
    details: "מהנדסת תוכנה ב-edgio",
  },

  {
    imageUrl: GraduateFourtyOne,
    title: "אלכסנדר קפנגאוז",
    miniTitle: "בוגר תואר ראשון בלוגיסטיקה",
    details: 'ראש תחום לוגיסטיקה במנהלת תרגילים מטכ"ליים',
  },
];

export default graduatesData;
