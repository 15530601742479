import React from "react";
import "./ImageWithTitle.css";
function ImageWithTitle({ imageUrl, title, text }) {
  return (
    <div dir="rtl" className="image-with-title">
      <img
        className="image-title"
        src={imageUrl}
        alt={title}
        role="presentation"
        aria-hidden="true"
      />
      <h2 className="almoni-bold-title-image">{title}</h2>
      <p className="almoni-light-text-image">{text}</p>
    </div>
  );
}

export default ImageWithTitle;
