// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/landingPage_March_OPEN-21.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thank-page-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .thank-page-popup {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-repeat: no-repeat;
    width: 50%;
    height: 50%;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.3);
}
  
  .thank-page-popup h2 {
    margin-bottom: 10px;
  }
  
  .thank-page-popup button {
    background: #007bff;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .thank-page-popup button:hover {
    background: #0056b3;
  }
  
  .logo-image {
    margin-top: 10%;
    width: 50%;
  }

  @media (max-width: 768px)  {

    
  .thank-page-popup {
    width: 100%;
    height: auto;
}
  }`, "",{"version":3,"sources":["webpack://./src/pages/ThankPage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,yDAAgE;IAChE,sBAAsB;IACtB,4BAA4B;IAC5B,UAAU;IACV,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,sCAAsC;AAC1C;;EAEE;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,UAAU;EACZ;;EAEA;;;EAGA;IACE,WAAW;IACX,YAAY;AAChB;EACE","sourcesContent":[".thank-page-container {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background: rgba(0, 0, 0, 0.5);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 999;\r\n  }\r\n  \r\n  .thank-page-popup {\r\n    background-image: url(\"../images/landingPage_March_OPEN-21.png\");\r\n    background-size: cover;\r\n    background-repeat: no-repeat;\r\n    width: 50%;\r\n    height: 50%;\r\n    padding: 20px;\r\n    border-radius: 30px;\r\n    box-shadow: 0 0 0px rgba(0, 0, 0, 0.3);\r\n}\r\n  \r\n  .thank-page-popup h2 {\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .thank-page-popup button {\r\n    background: #007bff;\r\n    color: #fff;\r\n    padding: 8px 16px;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .thank-page-popup button:hover {\r\n    background: #0056b3;\r\n  }\r\n  \r\n  .logo-image {\r\n    margin-top: 10%;\r\n    width: 50%;\r\n  }\r\n\r\n  @media (max-width: 768px)  {\r\n\r\n    \r\n  .thank-page-popup {\r\n    width: 100%;\r\n    height: auto;\r\n}\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
