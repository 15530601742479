import React, { useEffect, useRef } from "react";
import "./ThankPage.css";
import logoImage from "../images/landingPage_March_OPEN-15.png";

function ThankPage({ onClose }) {
  const dialogRef = useRef(null);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  useEffect(() => {
    if (dialogRef.current) {
      dialogRef.current.focus();
    }
  }, []);

  return (
    <div
      className="thank-page-container"
      role="dialog"
      aria-modal="true"
      ref={dialogRef}
    >
      <div className="thank-page-popup">
        <img className="logo-image" alt="college logo" src={logoImage} />
        <h2>הייתה תקלה, אנא מלא את פרטיך שוב</h2>
        <button onClick={onClose}>סגור</button>
      </div>
    </div>
  );
}

export default ThankPage;
