import React, { useState } from "react";
import "./LastDetailsBox.css";
import SendButton from "../images/landingPage_March_OPEN-14.png";
import degreeOptions from "../data/degreeOptions";
import ThankPage from "../pages/ThankPage";

function LastDetailsBox() {
  const [selectedDegree, setSelectedDegree] = useState("none");
  const [selectedDegreeOption, setSelectedDegreeOption] = useState("");
  const [chosenValue, setChosenValue] = useState("");
  const [showThankPage, setShowThankPage] = useState(false);
  const [isButtonDisabledLast, setIsButtonDisabledLast] = useState(false);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setChosenValue(selectedValue);
  };

  const handleDegreeChange = (event) => {
    setSelectedDegree(event.target.value);
    setSelectedDegreeOption("");
  };

  const handleDegreeOptionChange = (event) => {
    setSelectedDegreeOption(event.target.value);
  };

  const handleSubmitLastBox = (event) => {
    event.preventDefault();

    const firstNameInput = document.getElementById("firstNameLastBox");
    const lastNameInput = document.getElementById("lastNameLastBox");
    const emailInput = document.getElementById("mailLastBox");
    const phoneInput = document.getElementById("phoneLastBox");
    const degree = document.getElementById("degree");
    const degreeInput = document.getElementById("degreeOptionLastBox");

    if (
      !firstNameInput.checkValidity() ||
      !lastNameInput.checkValidity() ||
      !emailInput.checkValidity() ||
      !phoneInput.checkValidity()
    ) {
      alert("Please fill in all the required fields.");
      return;
    } else {
      setIsButtonDisabledLast(true);

      const firstName = firstNameInput.value;
      const lastName = lastNameInput.value;
      const email = emailInput.value;
      const phone = phoneInput.value;
      const selectedOptionDegree = degree.options[degree.selectedIndex];
      const interested = selectedOptionDegree.textContent;
      const department = degreeInput.value;
      const selectedOption = degreeInput.options[degreeInput.selectedIndex];
      const departmentLabel = selectedOption.textContent;
      const username = process.env.REACT_APP_USERNAME;
      const password = process.env.REACT_APP_PASSWORD;
      const sourceId = process.env.REACT_APP_SOURCE_ID;
      const queryParams = new URLSearchParams(window.location.search);
      const utmSource = queryParams.get("utm_source");
      const utmMedium = queryParams.get("utm_medium");
      const utmCampaign = queryParams.get("utm_campaign");

      const jsonData = {
        data: {
          FIRSTNAME: firstName,
          LASTNAME: lastName,
          PHONE: phone,
          EMAIL: email,
          DEPARTMENT: department,
          INTERESTED: interested,
          DEPARTMENT_NAME: departmentLabel,
          username: username,
          password: password,
          SOURCE_ID: sourceId,
          utmSource: utmSource,
          utmMedium: utmMedium,
          utmCampaign: utmCampaign,
        },
      };

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        jsonData,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://openday.sapir.ac.il/api/process_request", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          window.location.href += "thankPage";
        })

        .catch((error) => console.error(error));
    }
  };

  const closeThankPage = () => {
    setShowThankPage(false);
  };

  return (
    <div>
      <form className="leaving-details-box-last" onSubmit={handleSubmitLastBox}>
        <h2>
          <span className="almoni-bold">לתיאום ייעוץ אישי </span>
          <span className="almoni-light">השאירו פרטים</span>
        </h2>
        <div className="input-group-last">
          <div className="input-group">
            <input
              type="text"
              id="firstNameLastBox"
              placeholder=" השם הפרטי שלי *"
              aria-label="my first name"
              label="שדהחובה"
              required
            />
            <input
              type="text"
              id="lastNameLastBox"
              placeholder="שם המשפחה שלי *"
              aria-label="my last name"
              required
            />
          </div>
          <input
            dir="rtl"
            type="tel"
            id="phoneLastBox"
            placeholder="הנייד שלי *"
            aria-label="my phone number"
            required
          />
          <input
            type="email"
            id="mailLastBox"
            placeholder="המייל שלי *"
            aria-label="my email adress"
            required
          />
        </div>
        <div className="input-group-last-select">
          <select
            id="degree"
            onChange={handleDegreeChange}
            value={selectedDegree}
            aria-label="i am intersted learning"
            required
          >
            <option value="none">מעניין אותי ללמוד *</option>
            <option value="bachelor">תואר ראשון</option>
            <option value="master">תואר שני</option>
            <option value="practicalEngineer">הנדסאים</option>
            <option value="beforeAcademic">קדם אקדמי</option>
          </select>
          <select
            id="degreeOptionLastBox"
            onChange={handleDegreeOptionChange}
            value={selectedDegreeOption}
            aria-label="my field of interest"
            required
          >
            <option value="">תחום העניין שלי *</option>
            {degreeOptions[selectedDegree].map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <button
            type="submit"
            className="send-group-last"
            disabled={isButtonDisabledLast}
          >
            <img id="send-button-last" alt="send-button" src={SendButton}></img>
          </button>
        </div>
        <p className="milga-text-last">
          *המימון מותנה בקבלת התקציב בפועל ובכפוף לתקנון שכ"ל
        </p>
      </form>
      {showThankPage && <ThankPage onClose={closeThankPage} />}
    </div>
  );
}

export default LastDetailsBox;
