// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-with-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.image-title{
    width: 50%;
}
.almoni-bold-title-image{
 font-family: 'ALMONI-TZAR-REGULAR-AAA';
 margin-top: 5%;
}
.almoni-light-text-image{
    font-family: 'ALMONI-TZAR-LIGHT-AAA';
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .almoni-light-text-image{
        width: 110%;
    }
}

`, "",{"version":3,"sources":["webpack://./src/component/ImageWithTitle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,UAAU;AACd;AACA;CACC,sCAAsC;CACtC,cAAc;AACf;AACA;IACI,oCAAoC;IACpC,iBAAiB;AACrB;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".image-with-title{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: space-around;\r\n    width: 100%;\r\n}\r\n\r\n.image-title{\r\n    width: 50%;\r\n}\r\n.almoni-bold-title-image{\r\n font-family: 'ALMONI-TZAR-REGULAR-AAA';\r\n margin-top: 5%;\r\n}\r\n.almoni-light-text-image{\r\n    font-family: 'ALMONI-TZAR-LIGHT-AAA';\r\n    font-size: 1.5rem;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    .almoni-light-text-image{\r\n        width: 110%;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
