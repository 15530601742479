import React, { useState, useEffect } from "react";
import "./Gallery.css";
import { useSpring, animated } from "react-spring";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NextArrowImage from "../images/landingPage_March_OPEN-17.png";
import PrevArrowImage from "../images/landingPage_March_OPEN-18.png";
import ProcessImage from "../images/landingPage_mimun-09.png";
import ProcessImageMobile from "../images/landingPage_mimun-10.png";

function Gallery({ items }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const animatedProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  const renderItem = (item, index) => (
    <animated.div key={index} style={animatedProps}>
      <div className="gallery-item">{item}</div>
    </animated.div>
  );

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <img
        src={PrevArrowImage}
        alt="Previous"
        className="slick-arrow slick-prev"
        onClick={onClick}
        style={{
          width: "35px",
          height: "auto",
          marginRight: "-50px",
        }}
      />
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <img
        src={NextArrowImage}
        alt="Next"
        className="slick-arrow slick-next"
        onClick={onClick}
        style={{
          width: "35px",
          height: "auto",
          marginLeft: "-50px",
        }}
      />
    );
  };

  const sliderSettings = {
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    infinite: true,
    speed: 1500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    touchThreshold: 10,
  };

  return (
    <div className="gallery-page">
      <div className="title-container">
        <h2 role="presentation" className="almoni-ultra-bold-title">
          ההרשמה לתוכניות הלימוד בספיר בעיצומה!
        </h2>
        <p className="almoni-light-title">
          יועצות ויועצי הלימודים שלנו כאן כדי ללוות אותך מהצעד הראשון ועד לבחירת
          תכנית הלימודים שמתאימה לך.
        </p>
        <div className="space-between">
          <p className="almoni-light-title">
            בשנת הלימודים תשפ"ה, סטודנטיות וסטודנטים שנה א' בספיר ישתלבו בעשייה
            חברתית משמעותית תמורת
          </p>
          <p className="almoni-light-title"> מימון שכר לימוד מלא*</p>
        </div>
        {isMobile && (
          <>
            <br></br>
            <h5 className="milga-text-gallery">
              *המימון מותנה בקבלת התקציב בפועל ובכפוף לתקנון שכ"ל
            </h5>
          </>
        )}
        <h2 className="title-gallery">איך זה עובד?</h2>
        <img
          src={!isMobile ? ProcessImage : ProcessImageMobile}
          id="process-image-row"
          alt="1- הרשמה ללימודים 2-התקבלת?welcome 3-בחירת פרויקט חברתצי מתוך הפרויקטים במעורבות חברתית 4- לימודים לצד עשייה חברתית 5 זכאות למלגה"
          role="presentation"
        />
        <h2 className="title-gallery">מעטפת ספיר</h2>
      </div>
      <br />
      <div className="button-container">
        <div className="gallery-arrow">
          <Slider {...sliderSettings} className="gallery-container">
            {items.map((item, index) => renderItem(item, index))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
