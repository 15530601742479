import React, { useEffect, useState } from "react";
import SapirImage from "../images/landingPage_March_OPEN-15.png";
import SapirImageMobile from "../images/landingPage_March_OPEN-40.png";
import SapirOpen from "../images/landingPage_March_OPEN-23.png";
import SapirOpenMobile from "../images/landingPage_March_OPEN-29.png";
import BigImage from "../images/landingPage_March_OPEN-16.png";
import LeavingDetails from "./LeavingDetails";
import ContactBottom from "../images/landingPage_March_OPEN-19.png";

import "./Header.css";
function Header() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenWidth();

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className="header">
      <div className="row-images">
        <img
          src={BigImage}
          alt="תמונה של סטודנטים מבלים בספיר - מימון שכר לימוד מלא לשנה א' בתשפה *המימון מותנה בקבלת התקציב בפועל ובכפוף לתקנון"
          className="right-image"
          role="presentations"
          aria-hidden="true"
        />
        <div className="col-images">
          <a
            href="https://www.sapir.ac.il/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={!isMobile ? SapirImage : SapirImageMobile}
              alt="לוגו מכללת ספיר"
              className="top-image"
            />
          </a>

          <img
            src={!isMobile ? SapirOpen : SapirOpenMobile}
            alt="ההרשמה בעיצומה לספיר"
            className="middle-image"
          />
          {isMobile && (
            <div>
              <img
                className="contact-image"
                src={ContactBottom}
                onClick={handleScrollToBottom}
              ></img>
            </div>
          )}
          {!isMobile && <LeavingDetails />}
        </div>
      </div>
    </div>
  );
}

export default Header;
